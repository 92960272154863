import React, {useState, useEffect} from 'react';
import './App.css';
import Header from './Component/Header'
import Body from './Component/Body';
import Loader from './Component/Loader/Loader'


function App() {

  const [lat, setLat] = useState(28.6331)
  const [log, setLog] = useState(77.2207)
  const [forcast, setforcast] = useState()
  const [address,setAddress] = useState(undefined)
  const [slicedaddress,setSlicedaddress] = useState()
  const [data, setData] = useState();
  
useEffect(() => {
      navigator.geolocation.getCurrentPosition(function(position) {
        console.log("run",position.coords.latitude, position.coords.longitude)
        getweatherda(position.coords.latitude, position.coords.longitude);
      });
      // getweatherda(28.6331, 77.2207);
    const init = async() =>{
      if(window.ethereum){
        window.ethereum.enable();
        let user = window.ethereum.selectedAddress
        setSlicedaddress(user)
        setAddress(user)
      }
      else{
        alert("Non Ethereum browser detected")
      }
    }
    init();
}, [])

// navigator.geolocation.getCurrentPosition(function(position) {
//   console.log("run",position.coords.latitude, position.coords.longitude)
// });


const getweatherda = async(Lat, Log) => {
      console.log("run")
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${Lat}&lon=${Log}&units=metric&appid=${process.env.REACT_APP_API_KEY}`, requestOptions)
      .then(response => response.json())
      .then(result => setData(result),
      )
      .catch(error => console.log('error', error));

      await fetch(`https://api.openweathermap.org/data/2.5/forecast/?lat=${Lat}&lon=${Log}&units=metric&appid=${process.env.REACT_APP_API_KEY}`, requestOptions)
      .then(response => response.json())
      .then(result => setforcast(result) )
      .catch(error => console.log('error', error));
  }

  const Connect = async() => {
    window.ethereum.enable();
    let user = await window.ethereum.selectedAddress
    setAddress(user)
  }

  // console.log(forcast,data,address)

  return (
    <div className="App">
     <div>
       <Header connect={Connect} user={address}/>
      {data != undefined && forcast != undefined && address ?<Body weatherData={data} forcast={forcast}/> :
      <div className='loader'>
         <Loader/>
      </div>
      }
      </div> 
    </div>
  );
}

export default App;
