import React from 'react'
import { BsBorderRight } from 'react-icons/bs'
import Logo from './../image/logo3.png'

export default function Header(props) {

    const slice =(address)=>{
        const sliced = address.slice(0,5)+"..."+address.slice(38)
        return sliced
    }
console.log(window.innerWidth)

    return (
        <div className='app-header'>
            <div className='location'>
                <img src={Logo} style={{height:'60px',borderRadius:'60px', border:'2px solid black'}}/>{window.innerWidth > 710 ? <h4>AccuForecast by<a className='mainwebsite' href='https://www.windflokiinu.info/' target='_blank'> Wind Floki</a></h4>: ''}
            </div>
            <div className='wallert-btn'>
               <button className='connect-btn' onClick={() => props.connect()}>{props.user ? slice(props.user) : "Connect Wallet"}</button>
            </div>
        </div>
    )
}
