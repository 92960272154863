import React,{useState,useEffect} from 'react'
import { BsFillSunFill,BsThermometerSun,BsSunFill, BsTwitter, BsMoonStarsFill } from 'react-icons/bs'
import {FaFacebook, FaTemperatureHigh, FaTiktok} from 'react-icons/fa'
import {WiStrongWind} from 'react-icons/wi'
import {RiWindyLine, RiDrizzleFill} from 'react-icons/ri'
import {BsFillCloudyFill,BsCloudFog2Fill, BsTelegram, BsFacebook, BsYoutube} from 'react-icons/bs'
import {IoMdThunderstorm} from 'react-icons/io'
import {GiRaining, GiSnowing} from 'react-icons/gi'
import {TiWeatherPartlySunny} from 'react-icons/ti'
import weatherLogo from './../image/weather.png'


function Body({weatherData,forcast}) {
   const [weathericon, setWeathericon] =  useState()
   const [hour, setHour] = useState(0)
   const [iconhour, setIconhour] = useState(0)
   const [min, setMin] = useState(0)
   console.log(weatherData)
    
   useEffect(() => {
    if (weatherData.weather != undefined && weatherData.weather[0].main === 'Thunderstorm') {
        setWeathericon(<div style={{textAlign:'center'}}><IoMdThunderstorm size={60} fill='#37525b'/> </div>)

       } else if (weatherData.weather != undefined && weatherData.weather[0].main === 'Drizzle') {
        setWeathericon( weathericon = <div style={{textAlign:'center'}}><RiDrizzleFill size={60} fill='#66abc4'/></div>)

       } else if ( weatherData.weather != undefined && weatherData.weather[0].main === 'Rain') 
       {
        setWeathericon (weathericon = <div style={{textAlign:'center'}}><GiRaining size={60} fill='#575e60'/> </div>)
      } 
      else if (weatherData.weather != undefined && weatherData.weather[0].main === 'Snow') 
      {
        setWeathericon(<div style={{textAlign:'center'}}><GiSnowing size={60} fill='#cecece'/> </div>)
       } 
       else if (weatherData.weather != undefined && weatherData.weather[0].main === 'Clear')
        {
        setWeathericon(<div style={{textAlign:'center'}}><BsSunFill size={60} fill='#f3f49a'/> </div>)
       } else if (weatherData.weather != undefined && weatherData.weather[0].main === 'Clouds')
        {
        setWeathericon(weathericon = <div style={{textAlign:'center'}}><BsFillCloudyFill size={60} fill='#d6d6d6'/> </div>)
       } else {
        setWeathericon(<div style={{textAlign:'center'}}><BsCloudFog2Fill size={60} fill='#d6d6d6'/></div>)
     }
        var d = new Date(); 
        let hour = d.getHours();
       
        
        let min = d.getMinutes(); 
        setMin(min)
   }, [])

   const timerConvertor =(unixTime) =>{
    var a = new Date(unixTime * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    // setIconhour(hour)
    var time = date + ' ' + month + ' ' + year
    return time
   }


    return (
        <div className='weather-box-body container'>
            <div className='box-header container'>
                    <div>
                    <h3>{weatherData.name}/{weatherData.sys.country}</h3>
                    <p>Latitude {weatherData.coord.lat}, Longitude {weatherData.coord.lon}</p>
                    </div>
                    
                    <div className='sun-icon'>
                       <img src={weatherLogo} style={{width:50}}/>
                        <p>{timerConvertor(weatherData.dt)}</p>
                        
                    </div>
            </div>
            <hr/>
            <div className='details-box'>
                <div className='currentTemp'>
                    <div className='temp'>
                    {weatherData.sys.country === "US" ? <h2><BsThermometerSun size={30} fill='#ff214d'/> Current {Math.floor(weatherData.main.temp * (9/5)) + 32} &#8457;</h2>  : <h2><BsThermometerSun size={30} fill='#ff214d'/> Current {Math.floor(weatherData.main.temp)} &#8451;</h2>}
                        {/* <p><FaTemperatureHigh size={15} fill='#ff214d'/>&nbsp;Max {Math.floor(weatherData.main.temp_max)} &#8451;&nbsp;</p> */}
                        {weatherData.sys.country === "US" ? <p><FaTemperatureHigh size={15} fill='#ff214d'/>&nbsp; Max {Math.floor(weatherData.main.temp_max * (9/5)) + 32} &#8457;</p>  : <p><FaTemperatureHigh size={15} fill='#ff214d'/>&nbsp;Max {Math.floor(weatherData.main.temp_max)} &#8451;&nbsp;</p>}
                    </div>
                    <div className='cloud'>
                        {weathericon}&nbsp;&nbsp;{weatherData.weather[0].main}
                    </div>
                </div>
                <hr2/> 
                <div className='wind-details'>
                {weatherData.sys.country === "US" ? <h3><WiStrongWind size={25}/>&nbsp;Wind {weatherData.wind.speed} MPH</h3>  : <h3><WiStrongWind size={25}/>&nbsp;Wind {weatherData.wind.speed} KPH</h3>}
                    

                    <p>Deg {weatherData.wind.deg}<RiWindyLine/></p> 
                </div>
            
            </div>
            <hr2/>
            <div className='today-and-tomorrow'>

            <div className='today'>
                <h3>Today's like</h3>
                <div className='humidity'>
                <p>Humidity</p> 
                <p>{weatherData.main.humidity}%</p> 
                </div>
                <hr2/>
                <div className='humidity'>
                <p>Pressure</p> 
                <p>{weatherData.main.pressure}mb</p> 
                </div>
                <hr2/>
                <div className='humidity'>
                <p>Feels like</p> 
               
                {weatherData.sys.country === "US" ? <p>{Math.floor(weatherData.main.feels_like * (9/5))  + 32}&#8457;</p>   : <p>{Math.floor(weatherData.main.feels_like)}&#8451;</p>}
                </div>
            </div>
            <div className='tomorrow'>
            <h3>Tomorrow</h3>
                <div className='humidity'>
                {weatherData.sys.country === "US" ? <p>Temperature:&nbsp;&nbsp; {Math.floor(forcast.list[3].main.temp * (9/5))  + 32} &#8457;</p>  : <p>Temperature:&nbsp;&nbsp; {Math.floor(forcast.list[3].main.temp)}&#8451;</p>}
                {forcast.list != undefined ? <p>Weather:&nbsp;&nbsp; {forcast.list[3].weather[0].main}</p> : "0%" }
                </div>
                <hr2/>
                <div className='humidity'>
                <p>Pressure</p> 
                {forcast.list != undefined ?  <p>{forcast.list[3].main.pressure}mb</p> : '0mb' }
                </div>
                <hr2/>
                <div className='humidity'>
                <p>Feels like </p> 
                {weatherData.sys.country === "US" ? <p>{Math.floor(forcast.list[3].main.feels_like * (9/5))  + 32} &#8457;</p>   : <p>{Math.floor(forcast.list[3].main.feels_like)}&#8451;</p> }
                </div>
            </div>
            </div>
        <div className='socialLinks'>
            <a href='https://t.me/WFLOchat' target='_blank'><BsTelegram size={30}/></a>
            <a href='https://twitter.com/windflokiinu' target='_blank'><BsTwitter size={30}/></a>
            <a href='https://www.facebook.com/WindFlokiInu/' target='_blank'><BsFacebook size={30}/></a>
            <a href='https://www.youtube.com/channel/UCz0IEAgdB2BoBC_9gAPEUAA' target='_blank'><BsYoutube size={30} fill='#B71418'/></a>
        </div>
        </div>
    )
}

export default Body;
